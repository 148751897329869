<template>
  <div class="container-page big">
    <HeaderBlock
      :title="pageTitle"
      :modifiedRangeData="modifiedRangeData"
      :modifiedRangeDataVisible="request.isSecond"
      :bulkEditingVisible="!grouping"
      :selectedIds="selectedIds"
      :changeLog="changeLog.isActive"
      unilateral
      @create="editCard(0)"
      @clear="clearFilter"
      @export="exportExcel"
      @import="importExcel"
      @editView="editView"
      @bulkEditing="bulkEditing"
      @changesPeriod="showModifiedRangeModal"
      @backRegistry="showRegistry"
      @changeValueGrouping="changeValueGrouping"
      @onBackChangesOverPeriod="filterModified"
    />

    <div class="table-shadow">
      <div class="table-container-base table-container-base--sort js-table-arrow">
        <table v-if="!grouping && !changeLog.isActive">
          <HeaderTable
            :modifiedRangeData="modifiedRangeData"
            :tableHeader="getTableHeaderUnilateral"
            @changeSorting="changeSorting"
            @changeFilter="changeFilter"
            @changeSelectAll="changeSelectAll"
            ref="headerTable"
          />
          <TableBody :itemsBody="page.items" :isLoading="isLoading">
            <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
              <td :class="[modifiedRangeData.isActive ? 'column-icon-88' : 'column-icon']">
                <div class="wrap-icon">
                  <IconComponent
                    v-if="modifiedRangeData.isActive"
                    class="icon--full-svg"
                    @click="switchChangelog(item.id)"
                    name="time-redesign"
                  />
                  <IconComponent class="icon--full-svg" @click="editCard(item.id)" name="edit" />
                  <IconComponent class="icon--full-svg delete" @click="deleteCard(item.id)" name="delete-not-fill" />
                </div>
              </td>
              <td class="width-46"><input type="checkbox" v-model="selectedIds" :value="item.id" /></td>
              <td v-if="getVisibleItems.includes(tableIdColumns.tradingOperation)" class="width-192">
                {{ item.tradingOperation?.name || '' }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.countries)" class="width-192">
                {{ item.country?.nameFull || item.country?.name || '' }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.name)" class="width-192">{{ item.name }}</td>
              <td v-if="getVisibleItems.includes(tableIdColumns.tnvedGroup)" class="width-192">
                {{ item.tnvedAlias }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.tnvedCodes)" class="width-192">
                {{ item.tnvedCodes }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.industries)" class="width-192">
                {{ item.industryNames?.join('\n') }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.periodDate)" class="width-226">
                {{ item.periodDateFrom ? `с ${$momentFormat(item.periodDateFrom, 'DD.MM.YYYY')}` : '' }}
                {{ item.periodDateTo ? `по ${$momentFormat(item.periodDateTo, 'DD.MM.YYYY')}` : '' }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.legalActs)" class="width-192">{{ item.legalActs }}</td>
              <td v-if="getVisibleItems.includes(tableIdColumns.comment)" class="width-232">{{ item.comment }}</td>
              <td v-if="getVisibleItems.includes(tableIdColumns.countryCoverages)" class="width-192">
                {{ item.countryCoverage?.name || '' }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.isActive)" class="width-192">
                {{ item.isActive ? 'да' : 'нет' }}
              </td>
            </tr>
          </TableBody>
        </table>
        <table v-else-if="grouping && !changeLog.isActive">
          <thead>
            <tr>
              <th></th>
              <th class="sort width-192">Группа товаров</th>
              <th class="sort width-192">Код ТН ВЭД ЕАЭС</th>
              <th class="sort width-192">Нетарифная мера</th>
              <th class="sort width-192">Правовой акт</th>
              <th class="sort width-192">Комментарий</th>
            </tr>
          </thead>
          <TableBody :itemsBody="registryTreeList" :isLoading="isLoading">
            <tr v-for="(item, index) in registryTreeList" :key="index" @dblclick="editCard(item.id)">
              <template v-if="item.level < 3">
                <td class="center bold" colspan="7">{{ formatNum(item) }}.&nbsp;{{ item.name }}</td>
              </template>
              <template v-else>
                <td :class="[modifiedRangeData.isActive ? 'column-icon-88' : 'column-icon']">
                  <div class="wrap-icon">
                    <IconComponent
                      v-if="modifiedRangeData.isActive"
                      class="icon--full-svg"
                      @click="switchChangelog(item.id)"
                      name="time-redesign"
                    />
                    <IconComponent class="icon--full-svg" @click="editCard(item.id)" name="edit" />
                    <IconComponent class="icon--full-svg delete" @click="deleteCard(item.id)" name="delete-not-fill" />
                  </div>
                </td>
                <td class="width-192">{{ item.num }}.&nbsp;{{ item?.tnvedAlias }}</td>
                <td class="width-192">{{ item.tnvedCodes }}</td>
                <td class="width-226">
                  <p>{{ item.name }}</p>
                  <p>
                    {{ item.periodDateFrom ? `с ${$momentFormat(item.periodDateFrom, 'DD.MM.YYYY')}` : '' }}
                    {{ item.periodDateTo ? `по ${$momentFormat(item.periodDateTo, 'DD.MM.YYYY')}` : '' }}
                  </p>
                </td>
                <td class="width-192">{{ item.legalActs }}</td>
                <td class="width-192">{{ item.comment }}</td>
              </template>
            </tr>
          </TableBody>
        </table>
        <table v-else-if="changeLog.isActive">
          <HeaderTable
            :modifiedRangeData="modifiedRangeData"
            :tableHeader="getTableHeaderUnilateral"
            @changeSorting="changeSortingChangeLog"
            @changeFilter="changeFilterChangeLog"
            ref="headerTable"
            changeLog
          />
          <TableBody :itemsBody="page.items" :isLoading="isLoading">
            <tr v-for="(item, index) in page.items" :key="item.id" @dblclick="editCard(item.id)">
              <td>
                <div class="wrap-icon">
                  <IconComponent
                    @click="updateMemory(item)"
                    v-tooltip="'Восстановить запись'"
                    class="icon--full-svg"
                    name="restore"
                  />
                </div>
              </td>
              <td :class="['width-138', getClassStatus(item.actionId)]">{{ item.actionName }}</td>
              <td class="width-138">{{ $momentFormat(item.created, 'DD.MM.YYYY') }}</td>
              <td class="width-192">{{ item.userName }}</td>
              <td :class="['width-192', getClassChange(index, 'tradingOperation', { name: 'name' })]">
                {{ item.entityDto.tradingOperation?.name || '' }}
              </td>
              <td :class="['width-192', getClassChange(index, 'country', { name: 'nameFull' })]">
                {{ item.entityDto.country?.nameFull }}
              </td>
              <td :class="['width-192', getClassChange(index, 'name')]">{{ item.name }}</td>
              <td :class="['width-192', getClassChange(index, 'tnvedGroup', { name: 'name' })]">
                {{ item.entityDto?.tnvedAlias || '' }}
              </td>
              <td :class="['width-192', getClassChange(index, 'tnvedCodes')]">
                {{ item.entityDto.tnvedCodes }}
              </td>
              <td :class="['width-192', getClassChange(index, 'industryNames')]">
                {{ item.entityDto.industryNames?.join('\n') }}
              </td>
              <td
                :class="['width-226', getClassChange(index, 'periodDateFrom'), getClassChange(index, 'periodDateTo')]"
              >
                {{
                  item.entityDto.periodDateFrom ? `с ${$momentFormat(item.entityDto.periodDateFrom, 'DD.MM.YYYY')}` : ''
                }}
                {{
                  item.entityDto.periodDateTo ? `по ${$momentFormat(item.entityDto.periodDateTo, 'DD.MM.YYYY')}` : ''
                }}
              </td>
              <td :class="['width-192', getClassChange(index, 'legalActs')]">{{ item.entityDto.legalActs }}</td>
              <td :class="['width-232', getClassChange(index, 'comment')]">{{ item.entityDto.comment }}</td>
              <td :class="['width-192', getClassChange(index, 'countryCoverage', { name: 'name' })]">
                {{ item.entityDto.countryCoverage?.name || '' }}
              </td>
              <td :class="['width-192', getClassChange(index, 'isActive')]">
                {{ item.entityDto.isActive ? 'да' : 'нет' }}
              </td>
            </tr>
          </TableBody>
        </table>
      </div>
    </div>

    <PaginationRedesigned
      v-if="page.pagesTotal > 1 && !isLoading"
      :page-count="page.pagesTotal"
      :current-page="page.pageNumber"
      @change="onPageChange($event, false)"
      @more="onPageChange($event, true)"
    />

    <ModalComponent
      v-model="importModal"
      title="Импорт файла"
      content-class="modal--new"
      @close="closeModals('importModal')"
      headType="redesigned"
      bodyType="redesigned"
      @update:modelValue="closeModals('importModal')"
    >
      <ImportModal @doimport="doImport" @close="closeModals('importModal')" ref="importModal" />
    </ModalComponent>

    <ModalComponent
      v-model="importDataModal"
      :width="1000"
      title="Импорт файла"
      content-class="modal--new"
      @close="closeModals('importDataModal')"
      headType="redesigned"
      bodyType="redesigned"
    >
      <UnilateralNonTrImportComponent :import-data="importData" @close="closeAndReload" :nn="nn" />
    </ModalComponent>

    <ModalComponent
      v-model="modifiedRangeData.showModal"
      title="Изменения за период"
      content-class="modal--new"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModifiedModal"
      @update:modelValue="closeModifiedModal"
    >
      <UnilateralNonTrFilterModifiedComponent
        ref="modifiedRangeDataModal"
        @filter="filterModified"
        @close="closeModifiedModal"
      />
    </ModalComponent>

    <ModalComponent
      v-model="bulkEditingModal"
      content-class="modal--new"
      title="Массовое редактирование записей"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModals('bulkEditingModal')"
      @update:modelValue="closeModals('bulkEditingModal')"
    >
      <ContentPopupBulkEditing
        :selectedItems="selectedIds"
        :statusOptions="getStatusOptions"
        :changePromotion="false"
        @closePopup="closeModals('bulkEditingModal')"
        @save="bulkEditingChange"
        ref="bulkEditingModal"
        unilatera
      />
    </ModalComponent>

    <ModalComponent
      v-model="editViewModal"
      content-class="modal--new"
      title="Настройка вида отображения"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModals('editViewModal')"
      @update:modelValue="closeModals('editViewModal')"
    >
      <ContentPopupEditView
        :listCheckbox="getTableHeaderUnilateral"
        @save="changeColumnTable"
        @closePopup="closeModals('editViewModal')"
        ref="editViewModal"
      />
    </ModalComponent>

    <!-- статус Массового редактирования и Настройка вида отображения -->
    <ModalInfo
      v-model="isOpenModalInfo"
      @closeModal="closeModalInfo"
      :titleModal="titleModal"
      :textModal="textModal"
      :modalIconName="modalIconName"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import TableBody from '@/common/components/redesigned/TableBody';
  import Constants from '@/common/constants';
  import modalInfo from '@/common/mixins/modalInfo';
  import removeEmptyValuesFromObject from '@/common/mixins/removeEmptyValuesFromObject';
  import IconComponent from '@/common/ui/IconComponent';

  import ImportModal from '@/components/modals/ImportModal';
  import ModalComponent from '@/components/modals/ModalComponent';

  import UnilateralNonTrFilterModifiedComponent from '@/modules/measure/components/UnilateralNonTrFilterModifiedComponent';
  import UnilateralNonTrImportComponent from '@/modules/measure/components/UnilateralNonTrImportComponent';

  import Api from '../api/unilateralnontr';
  import ContentPopupBulkEditing from '../components/ContentPopupBulkEditing';
  import ContentPopupEditView from '../components/ContentPopupEditView';
  import HeaderBlock from '../components/HeaderBlock';
  import HeaderTable from '../components/unilateralComponents/HeaderTable';
  import duplicateMethods from '../mixins/duplicateMethods';
  import {
    UNILATERAL_NON_TTR_REGISTRY_ACTIONS_NAME,
    UNILATERAL_NON_TTR_REGISTRY_GETTERS_NAME,
    UNILATERAL_NON_TTR_REGISTRY_MUTATIONS_NAME,
  } from '../store/unilateralNonTrRegistry/name.js';
  import { MODAL_INFO_MESSAGES, TABLE_ID_UNILATERAL } from '../utils/constants';

  export default {
    name: 'UnilateralNonTrRegistry',
    components: {
      IconComponent,
      ModalComponent,
      ImportModal,
      UnilateralNonTrImportComponent,
      UnilateralNonTrFilterModifiedComponent,
      ModalInfo,
      PaginationRedesigned,
      ContentPopupBulkEditing,
      ContentPopupEditView,
      HeaderBlock,
      HeaderTable,
      TableBody,
    },
    mixins: [removeEmptyValuesFromObject, modalInfo, duplicateMethods],
    props: {
      pageTitle: String,
      isSecond: Boolean,
    },
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          isSecond: false,
          sortOrder: 0,
          sortColumn: 0,
        },
        grouping: false,
        isLoading: false,
        importInProgress: false,
        importModal: false,
        importData: {},
        importDataModal: false,
        nn: 1,
        filter: {},
        selectedIds: [],
        modifiedRangeData: {
          isActive: false,
          showModal: false,
          filter: null,
          filterText: null,
        },
        bulkEditingModal: false,
        editViewModal: false,
        tableIdColumns: TABLE_ID_UNILATERAL,
      };
    },
    computed: {
      ...mapGetters({
        getTableHeaderUnilateral: UNILATERAL_NON_TTR_REGISTRY_GETTERS_NAME.getTableHeaderUnilateral,
      }),

      getVisibleItems() {
        return this.getTableHeaderUnilateral.filter((i) => i.visible).map((i) => i.id);
      },

      registryTreeList() {
        let result = [];
        if (!this.page.items?.length) return result;
        this.page.items.forEach((tradingOperation, opIndex) => {
          tradingOperation.level = 0;
          tradingOperation.num = opIndex + 1;
          result.push(tradingOperation);
          if (tradingOperation?.items?.length) {
            tradingOperation.items.forEach((country, countryIndex) => {
              country.level = 1;
              country.num = countryIndex + 1;
              result.push(country);
              country.items.forEach((state, stateIndex) => {
                state.level = 2;
                state.num = country.num + '.' + (stateIndex + 1);
                result.push(state);
                state.items.forEach((entry, entryIndex) => {
                  entry.level = 3;
                  entry.num = entryIndex + 1;
                  result.push(entry);
                });
              });
            });
          }
        });
        return result;
      },

      getFilterData() {
        let dataFilter = {};
        if (this.modifiedRangeData.isActive) {
          dataFilter = this.objectFormatter(this.modifiedRangeData.filter);
          if (dataFilter.tnvedId) dataFilter.tnvedIds = [dataFilter.tnvedId];
        } else {
          dataFilter = this.objectFormatter(this.filter);
          if (this.filter.isActive) {
            dataFilter.isActive = this.filter.isActive == 1;
          }
        }
        return dataFilter;
      },
    },
    created() {
      this.request.isSecond = this.isSecond;
      this.loadDictionaries();
      this.loadPage();
    },
    methods: {
      ...mapActions({
        changeParametersMulti: UNILATERAL_NON_TTR_REGISTRY_ACTIONS_NAME.changeParametersMulti,
        findAuditLog: UNILATERAL_NON_TTR_REGISTRY_ACTIONS_NAME.findAuditLog,
        update: UNILATERAL_NON_TTR_REGISTRY_ACTIONS_NAME.update,
      }),

      ...mapMutations({
        updateListTableHeaderUnilateral: UNILATERAL_NON_TTR_REGISTRY_MUTATIONS_NAME.updateListTableHeaderUnilateral,
      }),

      loadDictionaries() {
        this.getNSICountryCoverages();
        this.getNSIIndustry();
        this.getNSIMoveDirections();
        this.countriesGet();
      },

      loadPage(append) {
        this.isLoading = !append;
        Api.search(
          {
            ...this.request,
            ...this.getFilterData,
          },
          this.grouping,
        )
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
          .catch((error) => {
            console.error('this.loadPage:', error);
          });
      },

      editCard(id) {
        if (this.isSecond) {
          this.$router.push({ name: 'UnilateralNonTrCardOdnostoron', params: { id: id, action: 'edit' } });
        } else {
          this.$router.push({ name: 'UnilateralNonTrCard', params: { id: id, action: 'edit' } });
        }
      },

      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            Api.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена.',
                  'error',
                );
              });
          }
        });
      },

      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        if (this.changeLog.isActive) {
          this.loadChangeLog(addToExist);
        } else {
          this.loadPage(addToExist);
        }
      },

      formatNum(item) {
        if (item.level === 0) {
          return item.id === Constants.tradingOperation.export ? 'I' : 'II';
        }
        return item.num;
      },

      exportExcel() {
        Api.exportExcel({
          ...this.getFilterData,
          isSecond: this.isSecond,
        });
      },

      importExcel() {
        this.importModal = true;
      },

      doImport(file) {
        this.closeModals('importModal');
        this.importInProgress = true;
        Api.uploadFile(file)
          .then((response) => {
            this.importInProgress = false;
            this.importData = response.data;
            this.importDataModal = true;
            this.nn++;
          })
          .catch(() => {
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },

      closeAndReload() {
        this.closeModals('importDataModal');
        this.loadPage();
      },

      clearSelection() {
        this.selectedIds = [];
      },

      showModifiedRangeModal() {
        this.modifiedRangeData.showModal = false;
        this.$nextTick(() => {
          this.modifiedRangeData.showModal = true;
        });
      },

      showRegistry() {
        this.closeModifiedModal();
        this.onBackChangesOverPeriod();
        this.loadPage();
      },

      onBackChangesOverPeriod() {
        this.request.pageNumber = 1;
        this.closeChangeLog();
      },

      closeModifiedModal() {
        this.modifiedRangeData.isActive = false;
        this.modifiedRangeData.showModal = false;
        this.modifiedRangeData.filter = null;
        this.modifiedRangeData.filterText = null;
        if (this.$refs.modifiedRangeDataModal) this.$refs.modifiedRangeDataModal.reset();
      },

      filterModified(filter, filterText) {
        this.modifiedRangeData.isActive = true;
        this.modifiedRangeData.showModal = false;
        if (!this.changeLog.isActive) {
          this.modifiedRangeData.filter = filter;
          this.modifiedRangeData.filterText = filterText;
        }
        this.onBackChangesOverPeriod();
        this.loadPage();
      },

      closeModals(type) {
        this[type] = false;
        if (this.$refs[type]) {
          this.$refs[type].reset();
        }
      },

      async bulkEditingChange(data) {
        const res = await this.changeParametersMulti({
          ...data,
          ids: this.selectedIds,
        });
        this.closeModals('bulkEditingModal');
        this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
        if (res.success) {
          this.textModal = MODAL_INFO_MESSAGES.SAVE_TEXT;
          this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
          this.isOpenModalInfo = true;
          this.loadPage();
        } else {
          this.textModal = MODAL_INFO_MESSAGES.ERROR_TEXT;
          this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
          this.isOpenModalInfo = true;
        }
      },

      bulkEditing() {
        this.bulkEditingModal = true;
      },

      editView() {
        this.editViewModal = true;
      },

      changeValueGrouping(val) {
        this.grouping = val;
        this.request.pageNumber = 1;
        this.loadPage();
      },

      changeSorting(sort) {
        const { sortColumn, sortOrder } = sort;
        this.request.sortOrder = sortOrder;
        this.request.sortColumn = sortColumn;
        this.loadPage();
      },

      changeFilter(filter) {
        this.request.pageNumber = 1;
        this.filter = filter;
        this.loadPage();
      },

      clearFilter() {
        this.filter = {};
        this.$refs.headerTable.clearFilter();
        this.loadPage();
      },

      changeSelectAll(value) {
        if (value) {
          this.selectedIds = [];
          this.selectedIds.push(...this.page.items.map((x) => x.id));
        } else {
          this.clearSelection();
        }
      },

      changeColumnTable(array) {
        this.updateListTableHeaderUnilateral(array);
        this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
        this.textModal = MODAL_INFO_MESSAGES.SAVE_TEXT;
        this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
        this.closeModals('editViewModal');
        this.isOpenModalInfo = true;
      },

      async loadChangeLog(append) {
        const dataFilter = this.objectFormatter(this.changeLog.filter);
        const res = await this.findAuditLog({
          entityId: this.changeLog.id,
          ...this.request,
          ...dataFilter,
        });
        if (res.success) {
          if (!append) {
            this.page = res.data;
          } else {
            this.page.pageNumber = res.data.pageNumber;
            this.page.items = this.page.items.concat(res.data.items);
          }
          this.changeLog.isActive = true;
          this.isLoading = false;
        } else {
          Constants.alert.fire(Constants.errorTitle, Constants.commonError, 'error');
        }
      },

      async updateMemory(item) {
        const data = {
          ...item.entityDto,
          id: this.changeLog.id,
        };
        if (data.created) delete data.created;
        const res = await this.update(data);
        if (res.success) {
          this.loadChangeLog();
        } else {
          Constants.alert.fire('Сохранение', Constants.commonError, 'error');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
</style>
