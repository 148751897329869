<template>
  <div class="bulk-editing-popup">
    <p class="bulk-editing-popup__records">
      <span>Количество выбранных записей:</span>&ensp;<span class="bulk-editing-popup__records-quantity">{{
        selectedItems.length
      }}</span>
    </p>
    <div class="bulk-editing-popup__row">
      <p class="bulk-editing-popup__title-values">Укажите новые значения для записей:</p>
    </div>
    <div class="bulk-editing-popup__row">
      <p class="label-field">Изменение статуса</p>
      <div class="bulk-editing-popup__field-wrap">
        <SelectMultiComponent
          v-model="form.statusId"
          placeholder="Выберите"
          modifier="rectangular"
          :options="statusOptions"
          :groups="groupsSelect"
          clearClass
        />
        <IconComponent :class="['bulk-editing-popup__icon', { active: form.statusId }]" name="arrow-choice" />
      </div>
    </div>
    <div class="bulk-editing-popup__row" v-if="changePromotion">
      <p class="label-field">Продление</p>
      <div class="bulk-editing-popup__field-wrap">
        <ComponentInput placeholder="Введите" mod="rectangular" v-model="form.prolongation" />
        <IconComponent :class="['bulk-editing-popup__icon', { active: form.prolongation }]" name="arrow-choice" />
      </div>
    </div>
    <div v-if="changeDate" class="bulk-editing-popup__row bulk-editing-popup__row--flex">
      <p class="label-field">Изменение срока применения меры:</p>
      <div class="bulk-editing-popup__column">
        <p class="label-field">Дата начала действия</p>
        <div class="bulk-editing-popup__field-wrap">
          <dateComponent v-model="form.dateFrom" modClass="modal-request" :upperLimit="upperLimitFrom" />
          <IconComponent :class="['bulk-editing-popup__icon', { active: form.dateFrom }]" name="arrow-choice" />
        </div>
      </div>
      <div class="bulk-editing-popup__column">
        <p class="label-field">Дата окончания действия</p>
        <div class="bulk-editing-popup__field-wrap">
          <dateComponent v-model="form.dateTo" modClass="modal-request" :lowerLimit="lowerLimitTo" />
          <IconComponent :class="['bulk-editing-popup__icon', { active: form.dateTo }]" name="arrow-choice" />
        </div>
      </div>
    </div>
    <div class="bulk-editing-popup__button">
      <ButtonComponent
        :disabled="!selectedItems.length || getDisabledButton"
        @click="save"
        mod="gradient-bg"
        class="button-redesign--popup-measure"
        >Сохранить</ButtonComponent
      >
      <ButtonComponent @click="closePopup" mod="transparent-bg" class="button-redesign--popup-measure"
        >Отменить</ButtonComponent
      >
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import dateComponent from '@/common/components/dateComponent.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import removeEmptyValuesFromObject from '@/common/mixins/removeEmptyValuesFromObject';
  import IconComponent from '@/common/ui/IconComponent';

  export default {
    name: 'ContentPopupBulkEditing',
    emits: ['closePopup', 'save'],
    mixins: [removeEmptyValuesFromObject],
    components: {
      ComponentInput,
      dateComponent,
      ButtonComponent,
      SelectMultiComponent,
      IconComponent,
    },
    props: {
      selectedItems: {
        type: Array,
        default: () => [],
      },
      statusOptions: {
        type: Array,
        default: () => [],
      },
      changePromotion: {
        type: Boolean,
        default: true,
      },
      changeDate: {
        type: Boolean,
        default: true,
      },
      groupsSelect: {
        type: Boolean,
        default: false,
      },
      initiative: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form: {
          statusId: '',
          prolongation: '',
          dateFrom: '',
          dateTo: '',
        },
      };
    },
    computed: {
      upperLimitFrom() {
        if (this.form.dateTo) {
          return moment(this.form.dateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date(2099, 0, 1);
      },
      lowerLimitTo() {
        return moment(this.form.dateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },

      getDisabledButton() {
        return !this.form.statusId && !this.form.prolongation && !this.form.dateFrom && !this.form.dateTo;
      },
    },
    methods: {
      closePopup() {
        this.$emit('closePopup');
      },

      save() {
        const data = this.objectFormatter(this.form);
        if (this.form.statusId && !this.initiative) {
          data.status = this.form.statusId === 1 ? true : false;
          delete data.statusId;
        }
        this.$emit('save', data);
      },

      /**
       * @public
       * Вызывается в родительском компоненте через ref
       */
      reset() {
        this.form.statusId = '';
        this.form.prolongation = '';
        this.form.dateFrom = '';
        this.form.dateTo = '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bulk-editing-popup {
    & > * {
      color: $base;
    }

    &__row {
      margin-bottom: 16px;

      &--flex {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__column {
      width: calc(50% - 8px);
      &:nth-child(even) {
        margin-right: 16px;
      }
    }

    &__icon {
      width: 24px;
      height: 24px;
      color: $light-grey-5;
      pointer-events: none;
      transition: color 0.3s ease;

      &.active {
        color: $green;
      }
    }

    &__button {
      display: flex;
      gap: 8px;
      margin-top: 32px;
    }

    &__records {
      font: $fira-16;
      margin-bottom: 32px;

      span {
        font: inherit;
      }

      &-quantity {
        background: -webkit-linear-gradient($blue, $green);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &__title-values {
      font: $fira-16-M;
    }

    &__field-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .label-field {
      width: 100%;
    }

    @media (max-width: $mobile) {
      &__column {
        width: 100%;
        &:nth-child(even) {
          margin-right: 0;
        }
        &:not(&:last-of-type) {
          margin-bottom: 16px;
        }
      }
    }
  }
</style>
