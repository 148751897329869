export const MODAL_INFO_MESSAGES = {
  SAVE_TITLE: 'Сохранение',
  SAVE_TEXT: 'Данные успешно сохранены',
  ERROR_TEXT: 'Не удалось применить изменения',
};

export const TABLE_ID_SINGLE = {
  year: 0,
  organName: 1,
  decisionDate: 2,
  decisionNumber: 3,
  initiator: 4,
  tnvedName: 5,
  tnvedNameBefore: 6,
  rateBefore: 7,
  tnvedNameAfter: 8,
  rateAfter: 9,
  applyDate: 10,
  comment: 11,
  industryNames: 12,
  prolongation: 13,
  isActive: 14,
  moveDirection: 15,
};

export const TABLE_ID_UNILATERAL = {
  tradingOperation: 0,
  countries: 1,
  name: 2,
  tnvedGroup: 3,
  tnvedCodes: 4,
  industries: 5,
  periodDate: 6,
  legalActs: 7,
  comment: 8,
  countryCoverages: 9,
  isActive: 10,
};

export const TABLE_ID_INITIATIVE = {
  employeeName: 0,
  organizationName: 1,
  organizationAddress: 2,
  phone: 3,
  director: 4,
  registrationDate: 5,
  registrationAddress: 6,
  authorizedFund: 7,
  created: 8,
  tnvedGroupName: 9,
  tnvedCode: 10,
  measureTypeId: 11,
  description: 12,
  statusId: 13,
  applicantGeneralInformation: 14,
  tradingOperation: 15,
  rates: 16,
  purpose: 17,
  responsible: 18,
  notes: 19,
  organizationInfo: 20,
};

export const MEASURE_TYPE_IDS = {
  INCREASE_EXPORT: 1,
  DECREASE_EXPORT: 2,
  INCREASE_IMPORT: 3,
  DECREASE_IMPORT: 4,
  TARIFF_QUOTA: 5,
  TARIFF_BENEFIT: 6,
};

export const MOVING_DIRECTION = {
  EXPORT: 1,
  IMPORT: 2,
};

export const movingDirectionOptions = [
  {
    id: MOVING_DIRECTION.EXPORT,
    text: 'Экспорт',
  },
  {
    id: MOVING_DIRECTION.IMPORT,
    text: 'Импорт',
  },
];

export const ID_STATUS_ACTIONS = {
  create: 1,
  edit: 2,
};

export const INITIATIVE_STATUSES = {
  processApplicantExport: 13,
  processApplicantImport: 14,
  processExport: 15,
  processImport: 18,
  inexpedientExport: 17,
  inexpedientImport: 20,
};

export const UPDATING_INITIATIVE_MESSAGES = {
  SUCCESS: 'Заявка успешно обновлена',
  FAILED: 'Не удалось обновить заявку',
};
