<template>
  <div class="edit-view-popup">
    <p class="edit-view-popup__title">Выберите столбцы для отображения в реестре</p>
    <div class="edit-view-popup__block">
      <div class="edit-view-popup__block-item">
        <ComponentInput
          type="checkbox"
          mod="checkbox-green"
          modLabel="small-size"
          id="all"
          label="Выбрать все"
          v-model="allVisible"
        />
      </div>
      <div v-for="checkbox in copyListCheckbox" :key="checkbox.id" class="edit-view-popup__block-item">
        <ComponentInput
          type="checkbox"
          mod="checkbox-green"
          modLabel="small-size"
          :id="checkbox.id"
          :label="checkbox.name"
          v-model="checkbox.visible"
        />
      </div>
    </div>
    <div class="edit-view-popup__button">
      <ButtonComponent
        @click="save"
        :disabled="!emptyChecked || noDifferences"
        mod="gradient-bg"
        class="button-redesign--popup-measure"
        >Сохранить</ButtonComponent
      >
      <ButtonComponent @click="closePopup" mod="transparent-bg" class="button-redesign--popup-measure"
        >Отменить</ButtonComponent
      >
    </div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';

  export default {
    name: 'ContentPopupEditView',
    emits: ['closePopup', 'save', 'editView'],
    components: {
      ComponentInput,
      ButtonComponent,
    },
    props: {
      listCheckbox: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        allVisible: true,
        copyListCheckbox: [],
      };
    },
    mounted() {
      this.arrayDuplication();
    },
    computed: {
      allChecked() {
        return this.emptyChecked === this.copyListCheckbox.length;
      },

      emptyChecked() {
        return this.copyListCheckbox.filter((i) => i.visible).length;
      },

      noDifferences() {
        const oldArr = this.listCheckbox.map((i) => i.visible);
        const newArr = this.copyListCheckbox.map((i) => i.visible);

        return String(oldArr) == String(newArr);
      },
    },
    methods: {
      arrayDuplication() {
        this.copyListCheckbox = JSON.parse(JSON.stringify(this.listCheckbox));
      },

      save() {
        this.$emit('save', JSON.parse(JSON.stringify(this.copyListCheckbox)));
      },

      closePopup() {
        this.$emit('closePopup');
      },

      /**
       * @public
       * Вызывается в родительском компоненте через ref
       */
      reset() {
        this.copyListCheckbox = JSON.parse(JSON.stringify(this.listCheckbox));
      },
    },
    watch: {
      allChecked(val) {
        if (val) {
          this.allVisible = true;
        } else {
          this.allVisible = false;
        }
      },

      allVisible(val) {
        if (val && !this.allChecked) {
          this.copyListCheckbox = this.copyListCheckbox.map((i) => ({ ...i, visible: true }));
        }
        if (!val && this.allChecked) {
          this.copyListCheckbox = this.copyListCheckbox.map((i) => ({ ...i, visible: false }));
        }
      },

      listCheckbox() {
        this.arrayDuplication();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .edit-view-popup {
    &__title {
      font: $fira-16-M;
      margin-bottom: 16px;
    }

    &__block {
      column-count: 2;

      &-item {
        margin-bottom: 16px;
      }
    }

    &__button {
      display: flex;
      gap: 8px;
      margin-top: 40px;
    }

    @media (max-width: $mobile) {
      &__block {
        column-count: 1;
      }

      &__button {
        margin-top: 8px;
      }
    }
  }
</style>
