import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request, grouping) {
    return grouping
      ? axios.get('/api/unilateralnontr/searchgroup', { params: request })
      : axios.get('/api/unilateralnontr/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/unilateralnontr/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/unilateralnontr/${data.id}`, data);
    else return axios.post(`/api/unilateralnontr`, data);
  },
  delete: function (id) {
    return axios.delete(`/api/unilateralnontr/${id}`);
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/unilateralnontr/ExportExcel', 'unilateralnontr.xlsx', request);
  },
  async uploadFile(file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`api/unilateralnontr/uploaddata`, formData);
  },
  async uploadSelectedData(items) {
    return axios.post(`api/unilateralnontr/uploadselecteddata`, items);
  },
  changeStatusMulti: function (data) {
    return axios.patch(`/api/unilateralnontr/changestatusmulti`, data);
  },
  async searchEventLog(request) {
    return await axios.get('/api/unilateralnontr/searchEventLog', { params: request });
  },
  async getPermissions(request) {
    return await axios.get('/api/unilateralnontr/eventLogPermissions', { params: request });
  },
};
